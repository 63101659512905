import {
  CAMERA_EASING_MODE,
  E_SDK_EVENT,
  MapView,
  Mappedin,
  MappedinDestinationSet,
  MappedinLocation,
  TGetVenueMakerOptions,
  TMapViewOptions,
  TShowVenueOptions,
  getVenueMaker,
  showVenue,
} from "@mappedin/mappedin-js";
import { useEffect, useMemo, useRef, useState } from "react";

import { JourneyController } from "@mappedin/mappedin-js/renderer/internal";
import { useLocalStorage, useViewportSize } from "@mantine/hooks";
import { Portal } from "@mantine/core";

export const useMap = (props: { identity?:string, venueOptions?: TShowVenueOptions, onLoad?: () => void }) => {
  const [mapId, setMapId] = useState<string | undefined>(undefined);

  const { height, width } = useViewportSize();
  const mapRef = useRef<HTMLDivElement | null>(null);

  const [mapView, setMapView] = useState<MapView | undefined>();

  const [venueOptions, setOptions] = useState<TMapViewOptions | undefined>(
    props.venueOptions
      ? props.venueOptions
      : {
          multiBufferRendering: true,
          outdoorView: {
            enabled: true,
          },
          shadingAndOutlines: true,
        }
  );

  // Store the venue object in a state variable
  const [venue, setVenue] = useState<Mappedin | undefined>();

  // const [venue, setVenue] = useLocalStorage<Mappedin | undefined>({
  //   key:  props.identity +  "-map-data",
  //   defaultValue: undefined,
  // });

  // Fetch data asynchronously whenever options are changed
  useEffect(() => {
    let ignore = false;

    const fetchData = async () => {
      try {

        if (mapId !== undefined && mapId !== "" && !mapId.startsWith("http")) {
          console.info("map -> fetchData", " mapId -> ", mapId);

          const options: TGetVenueMakerOptions = {
            key: "6633fa8a8004177ee42bcce3",
            secret:
              "0f5d31476a68a54e9205940dc2b8c78cc97eedaf4501ccf7cc7590c5c2f6c9cc",
            //mapId: "65e1b67aa1cbc80d8a98ebaa",
            //mapId: "6633461f269972f02bf83941",
            mapId: mapId,

          };

          const data = await getVenueMaker(options);
          // Update state variable after data is fetched
          if (!ignore) {
            setVenue(data);
          }
        }
      } catch (e) {
        // Handle error
        console.log(e);
        setVenue(undefined);
      }
    };
    fetchData();

    return () => {
      ignore = true;
    };
  }, [mapId]);

  const onLoad = (event: any) => {
      if (props.onLoad) {
        props.onLoad();
      }
  }

  useEffect(() => {
    async function renderVenue() {
      if (mapRef == null || venue == null) {
        return;
      }

      if (mapView != null && mapView.venue.venue.id === venue.venue.id) {
        return;
      }

      if (mapView != null) {
        mapView.destroy();
      }

      try {
        if (mapRef.current === null) return;

        const _mapView = await showVenue(mapRef.current, venue, venueOptions);

        // const layersOnMap = await _mapView.Layers.getAllLayersForMap(venue.maps[0]);
        // console.info("layers -> ", layersOnMap);

        //_mapView.Layers.showLayers(['']);

        //_mapView.Exporter.getCurrentSceneGLTF();

        //_mapView.FlatLabels.labelAllLocations();
        //_mapView.FloatingLabels.labelAllLocations();

        _mapView.on(E_SDK_EVENT.OUTDOOR_VIEW_LOADED, onLoad)

        setMapView(_mapView);

      } catch (e) {
        setMapView(undefined);
      }
    }

    renderVenue();
  }, [mapRef, venue, mapView]);

  useEffect(() => {
    if (mapView) {
      mapView.addInteractivePolygonsForAllLocations();

      mapView.currentMap.locations.forEach((location) => {
        //console.log("location ->", location.type);
      });

      // mapView.FlatLabels.labelAllLocations({
      //   appearance: {
      //     font: "Georgia",
      //     fontSize: 16,
      //     color: "#1c1c43",
      //     margin: 10,
      //   },
      // });

      //mapView.options.shadingAndOutlines = true;

      mapView.Camera.minZoom = 1;
    }
  }, [mapView]);

  const showdirections = (points: string[]) => {
    if (points.length === 0) return;

    mapView?.Journey.clear();

    let destinations: MappedinLocation[] = [];

    const startlocation = venue?.locations.find(
      (location) => location.name === points[0]
    );

    if (startlocation === undefined) return;

    for (let index = 1; index < points.length; index++) {
      const element = points[index];
      const location = venue?.locations.find(
        (location) => location.name === points[index]
      );

      if (location !== undefined) destinations.push(location);
    }

    const directions = startlocation.directionsTo(
      new MappedinDestinationSet(destinations)
    );

    console.info("Directions ->", directions);

    if (directions.length === 0) return;
    //if path is empty, return
    if (directions[0].path.length === 0) return;

    //Pass the directions to Journey to draw a path and icons.
    const controller = mapView?.Journey.draw(directions, {
      pathOptions: {
        color: "#035F1D",
        interactive: true,
        adjustedAltitude: false,
        nearRadius: 0.2,
        farRadius: 2.0,
      },
      inactivePathOptions: {
        nearRadius: 0.2,
        farRadius: 2.0,
        color: "#035F1D",
      },
    });
  };

  const showPointOnMap = (point: string) => {
    const _location = venue?.locations.find(
      (location) => location.name === point
    );

    if (_location) {
      mapView?.Camera.focusOn(
        {
          polygons: _location.polygons,
          nodes: _location.nodes,
        },
        {
          changeZoom: true,
          duration: 1000,
          easing: CAMERA_EASING_MODE.EASE_OUT,
        }
      );
    }
  };

  const showMap = (name: string) => {
    var map = venue?.maps.find((map) => map.name === name);
    if (map !== undefined) mapView?.setMap(map.id);
  };

  const renderMap = () => {
    return (
      // <Portal style={{display: 'absolute', top: '0px', left: '0px', right: '0px', bottom: '0px'}}>
      <div id="map" ref={mapRef} style={{ 
        //width: 600, 
        //height: 400,
        width: width,
        height: height, 
        inset: '0px',
        position: 'fixed' 
      }} />
      // </Portal>
    );
  };

  return {
    setMapId,
    renderMap,
    showMap,
    showdirections,
    showPointOnMap,
  };
};

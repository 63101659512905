import { useParams } from "react-router-dom";
import { useLanguage } from "../../common/language/Language";
import { ActionItem, AppMenu } from "./components/AppMenu";
import { Image as ContentImage } from "../../common/content/api/Content";
import {
  duotone,
  light,
  solid,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  ActionIcon,
  Alert,
  BackgroundImage,
  Image,
  Box,
  Button,
  Group,
  Paper,
  Stack,
  Text,
  Title,
  Portal,
  Drawer,
  Card,
  Input,
  LoadingOverlay,
  Center,
  ScrollArea,
  Loader,
  Modal,
  Divider,
  SimpleGrid,
  em,
  Radio,
  CardSection,
  NumberInput,
  Grid,
} from "@mantine/core";
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useDisclosure,
  useLocalStorage,
  useSessionStorage,
  useViewportSize,
  useWindowScroll,
  useMediaQuery,
} from "@mantine/hooks";
import {
  KeyPoint,
  KeypointGroup,
  Layer,
  ParticipantInfo,
  Question,
  TrainingItem
} from "./api/TrainingApi";
import { Exagard_Colors, GlobalAppsettings } from "../../GlobalSettings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { renderMarkdown } from "../../common/content/RenderMarkdown";
import { ContentItem } from "../../common/content/api/Content";
import { useContent } from "../../common/content/useContent";
import { useSubscribe } from "../../common/pubsub/usePubsub";
import { QRScanner } from "../app/QRScanner";

// Lottie
import Lottie from "lottie-react";
import stars from "./animations/stars.json";

import { ResponseData, ResultBase } from "../../common/api/common";
import { Http, fetcher } from "../../common/Fetcher";

import { useMap } from "./map/useMap";
import { UserFormInput } from "./components/UserInput";
import useSWR from "swr";
import { useFingerPrint } from "../../common/fingerprint/useFingerPrint";
import { MapModule } from "./3dmap/MapModule";

import App from 'pubsub-js';


export const TrainingModule = (props: {editMode: boolean, setAllowEdit: (value: boolean) => void}) => {
  const lang = useLanguage();

  const { id } = useParams();

  const { group } = useParams();

  const [error, setError] = useState<string>("");

  const { getContentItem } = useContent();

  const [scale, setScale] = useState<string>("1.0");

  const { height, width } = useViewportSize();

  // const mapRef = useRef<HTMLDivElement>(null);

  const [showKeypointList, setShowKeypointList] = useState<boolean>(true);

  const [startedRound, setStartedRound] = useState<boolean>(false);

  const [mapWidth, setMapWidth] = useState<number>(350);
  const [mapHeight, setMapHeight] = useState<number>(350);

  const [currentQRCode, setCurrentQRCode] = useState<string | undefined>(
    undefined
  );

  const {visitorId} = useFingerPrint();

  const viewport = useRef<HTMLDivElement>(null);

  const scrollTo = (y: any) => {
    viewport.current!.scrollTo({ top: y, behavior: "smooth" });
  };

  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

  const [mapScrollx, setmapScrollx] = useState<number>(0);
  const [mapScrolly, setmapScrolly] = useState<number>(0);

  const mapContainerRef = useRef<HTMLDivElement>(null);

  const [start, showStart] = useState<boolean>(false);
  const [exam, showExam] = useState<boolean>(false);
  const [firstKeypoint, setFirstKeypoint] = useState<boolean>(true);
  const [gotoNextQuestion, setGotoNextQuestion] = useState<boolean>(false);

  const [flashAction, setFlashAction] = useState<boolean>(false);

  const [achivements, showAchivements] = useState<boolean>(false);
  const achivementContent = useContent({
    contentID: "6ySZR9Mz3zRrjKMx3H5xSx",
    locale: lang.locale(),
    preview: false,
  });

  const [roundParticipants, setRoundParticipants] = useState<string | number>(
    1
  );
  const [zoomKeypoint, showZoomKeypoint] = useState<boolean>(false);

  // const [trainingList] = useLocalStorage<TrainingItem[]>({
  //   key: GlobalAppsettings.AppId + "-training",
  //   defaultValue: [],
  // });

  const [currentTraining, setCurrentTraining] = useState<TrainingItem>();

  const {data, isLoading, mutate: refresh} = useSWR<ResultBase<TrainingItem>>(
    () => '/api/training/GetTraining?id=' + id,
    fetcher,
    {
      refreshInterval: 0,
      keepPreviousData: true,
      onError: (error: any) => {
        console.error(error);
      },
    }
  );

  useEffect(() => {
    if (data?.success) {
      setCurrentTraining(data.result);
      if (props.setAllowEdit !== undefined && data.result.config.allowEdit !== undefined) {
        props.setAllowEdit(data.result.config.allowEdit);
      }
    }
  }, [data]);

  const [wrongAnswer, setWrongAnswer] = useState<boolean>(false);
  const [
    wrongAnswerDialog,
    { open: showWrongAnswerDialog, close: closeWrongAnswerDialog },
  ] = useDisclosure(false);

  const [current, setCurrent] = useState<TrainingItem>();
  const [currentLayer, setCurrentLayer] = useState<Layer | undefined>();

  const [currentkeypoints, setCurrentKeypoints] = useState<
    KeyPoint[] | undefined
  >();
  const [mapMediaRef, setMapMediaRef] = useState<string | undefined>(
    current?.layers[0].mediaRef
  );

  const [currentkeypoint, setCurrentKeypoint] = useState<KeyPoint | undefined>(
    undefined
  );

  const [participant, setParticipant] = useSessionStorage<
    ParticipantInfo | undefined
  >({ key: GlobalAppsettings.AppId + "-participant" });

  const [startContent, setStartContent] = useSessionStorage<
    ContentItem | undefined
  >({ key: GlobalAppsettings.AppId + "-startContent" });

  const [actions, setActions] = useState<ActionItem[]>([
    {
      title: "",
      action: "/rounds",
      icon: duotone("arrow-left"),
    },
  ]);

  const userOrgConfig = {
    //if value from config is undefined, set default value to false
    collectUser: current?.config.collectUser ?? false,
    collectUserList: current?.config.collectUserList ?? false,
    collctFingerPrint: current?.config.collectFingerPrint ?? false,
    collectOrgInfo: current?.config.collectorgInfo ?? false,
  };

  const [currentQuestion, setCurrentQuestion] = useState<Question>();
  const currentQuestionindex = useRef<number>(0);

  const onMapLoaded = () => {
      setStartGroup();
  };

  const setStartGroup = () => {
    const round = current;
    if (round !== undefined) {
      if (round.groups !== undefined && round.groups.length > 0) {
        if (group !== undefined) {
          const groupExists = round.groups.some((g) => g.id === group);
          if (groupExists) {
            console.info("Set group ->", group);
            setCurrentGroup(group);
          } else {
            console.info("group not found ->", group);
            setCurrentGroup(round.groups[0].id);
          }
        } else {
          setCurrentGroup(round.groups[0].id);
        }
      }
    }
  }

  const { setMapId, renderMap, showMap, showPointOnMap, showdirections } =
    useMap({
      identity: id,
      venueOptions: {
        labelAllLocationsOnInit: true,
        multiBufferRendering: true,
        outdoorView: {
          enabled: true,
        },
        shadingAndOutlines: true,
      },
      onLoad: onMapLoaded,
    });

  const setKeypointNavigation = () => {
    let keypoint = currentkeypoint;
    let round = current;

    setActions([
      {
        title: "",
        action: "",
        icon: duotone("arrow-left"),
        execute: () => {
          //set prev keypoint
          let index = keypoint?.order;
          if (index !== undefined && index > -1) {
            setCurrentKeypoint(round?.keyPoints[index - 1]);
          }
        },
      },
      {
        title: lang.Text("Next"),
        action: "",
        icon: duotone("arrow-right"),
        execute: () => {
          let index = keypoint?.order;
          //set next keypoint
          if (keypoint != undefined) {
            index = keypoint.order + 1;
          }

          if (
            index !== undefined &&
            round !== undefined &&
            index < round?.keyPoints.length
          ) {
            let nextKeypoint = round.keyPoints.find(
              (kp) => kp.group === currentGroup && kp.order === index
            );

            setCurrentKeypoint(nextKeypoint);
            // setShowKeypointList(true);
          }
        },
      },
    ]);
  };

  const findLastDoneItemMapRef = (keyPoints?: KeyPoint[]) => {
    if (keyPoints == undefined) return "";

    let last: KeyPoint | undefined;

    keyPoints.forEach((kp) => {
      if (kp.group === currentGroup && kp.isDone) {
        last = kp;
        console.log("Last done keypoint ->", last);
      }
    });

    if (last !== undefined) return last.mapRef;
    else return "";
  };

  const closeExam = () => {
    let keypoint = currentkeypoint;
    let round = current;

    //update keypoint
    let keyPoints = current?.keyPoints;
    if (keyPoints !== undefined) {
      keyPoints.forEach((item) => {
        if (item.id === keypoint?.id) {
          item.isDone = true;
        }
      });
    }
    console.info("Update keypoint ->", keypoint);

    if (keyPoints !== undefined && round !== undefined) {
      round.keyPoints = keyPoints;
      console.info("Update round ->", round);

      setCurrent(round);
    }

    setStartLocation(findLastDoneItemMapRef(keyPoints));

    setKeypointNavigation();
    setShowKeypointList(true);
    showExam(false);
  };

  useEffect(() => {
    let keypoints: KeyPoint[] = [];
    // let currentlayer = currentkeypoint?.layerRefs[0];
    if (current !== undefined) {
      current.keyPoints.forEach((keypoint) => {
        if (keypoint.layerRefs !== undefined) {
          keypoint.layerRefs.forEach((layer) => {
            if (layer === currentLayer?.id) {
              keypoints.push(keypoint);
            }
          });
        }
      });
    }
    if (current !== undefined) {
      current.layers.forEach((layer) => {
        if (layer.id === currentLayer?.id) {
          setMapMediaRef(layer.mediaRef);
        }
      });
    }
    setCurrentKeypoints(keypoints);
  }, [currentLayer]);


  const reportFinishedRound = () => {
    
    let params = {
      id: id,
      orgId: current?.orgId,
      fingerprint: visitorId,
      data: [
        {
          key: 'roundId',
          value: current?.id
        },
        {
          key: 'roundName',
          value: current?.title
        },
        {
          key: 'roundDescription',
          value: current?.description
        },
      ],
    };

    console.info('report event ->', params);

    if (user !== undefined && user.length > 0) {
      params.data.push({
        key: 'user',
        value: user
      });
    }

    if (userList !== undefined && userList.length > 0) {
      params.data.push({  
        key: 'userList',
        value: userList.join(',')
      });
    }

    if (orgInfo !== undefined && orgInfo.length > 0) {
      params.data.push({
        key: 'orgInfo',
        value: orgInfo  
      });
    }


    fetcher('/api/event/ReportFinishedRound',
      Http.POST,undefined,params).then((result) => {
        console.info('report finished round ->', result);
      });

  }

  useEffect(() => {
    if (current !== undefined) {
    
      //check if all keypoint are done
      let allDone = true;
      current.keyPoints?.forEach((item) => {
        if (!item.isDone) {
          allDone = false;
        }
      });

      if (allDone) {
        //show achivements
        showAchivements(true);

        reportFinishedRound();

        setActions([
          {
            title: "",
            action: "/",
            icon: duotone("home"),
          },
          // {
          //     title: lang.Text('Next'),
          //     action: '/achivements',
          //     icon: duotone('arrow-right')
          // }
        ]);
      } else {
        //Goto next keypoint
      }
    }
  }, [exam]);

  useEffect(() => {
    if (
      currentQuestion !== undefined &&
      currentQuestion.answers !== undefined
    ) {
      if (gotoNextQuestion) {
        if (wrongAnswer) {
          showWrongAnswerDialog();
        } else {
          ///nextQuestion();

          let index = currentQuestionindex.current;
          if (currentkeypoint?.exams[0].questions !== undefined) {
            setCurrentQuestionindex(index + 1);
          }
        }
        setGotoNextQuestion(false);
      }
    }
  }, [gotoNextQuestion]);

  const setCurrentQuestionindex = (i: number) => {
    if (i === currentkeypoint?.exams[0].questions.length) {
      console.info("last question");
      //last question, all answered?
      let allAnswered = true;
      //   currentkeypoint?.exams[0].questions.forEach((question) => {
      //     if (question.answers !== undefined) {
      //       let questionAnswered = false;
      //       question.answers.forEach((answer) => {
      //         if (answer.selected) {
      //           questionAnswered = true;
      //         }
      //       });
      //       if (!questionAnswered) {
      //         allAnswered = false;
      //       }
      //     }
      //   });

      if (allAnswered) {
        closeExam();
      }
      return;
    }

    if (
      currentkeypoint?.exams[0].questions !== undefined &&
      i < currentkeypoint?.exams[0].questions.length &&
      i >= 0
    ) {
      currentQuestionindex.current = i;
      console.info(currentQuestionindex.current);
    }
    setCurrentQuestion(
      currentkeypoint?.exams[0].questions[currentQuestionindex.current]
    );
  };

  useEffect(() => {
    if (!id) {
      setError("Round not found");
    } else {
      //find round
      const round = currentTraining;
      if (!round) {
        setError("Round not found");
      } else {
        if (startedRound) {
          //
        } else {
          setCurrent(round);
          setCurrentLayer(round.layers[0]);
          setCurrentQRCode(undefined);
          closeBindDialog();
          closeWrongDialog();
          setCurrentKeypoint(undefined);
          setError("");
          showExam(false);
          // showKeypointInfo(false);
          // setCurrentQuestionindex(0);
          setFlashAction(false);

          setMapId(round.layers[0].mediaRef);

          getContentItem(round.contentRef, lang.locale(), false).then(
            (result) => {
              setStartContent(result);
              setActions([
                {
                  title: "",
                  action: "/training",
                  icon: duotone("arrow-left"),
                },
                {
                  title: lang.Text("Start Round"),
                  action: "",
                  icon: duotone("arrow-right"),
                  execute: () => {
                    setMapMediaRef(round.layers[0].mediaRef);
                    showStart(false);
                    setActions([
                      {
                        title: "",
                        action: "/training",
                        icon: duotone("arrow-left"),
                      },
                      {
                        title: "",
                        action: "",
                        icon: duotone("arrow-right"),
                        execute: () => {
                          setCurrentKeypoint(round.keyPoints[0]);
                          // setShowKeypointList(false);
                        },
                      },
                    ]);
                  },
                },
              ]);
              showStart(true);
              setStartedRound(true);
            }
          );
        }
      }
    }
  }, [currentTraining, id]);

  const [keypointContent, setKeypointContent] = useState<
    ContentItem | undefined
  >(undefined);
  const [keypointInfo, showKeypointInfo] = useState<boolean>(false);

  const startExam = () => {
    setCurrentQuestionindex(0);
    setCurrentQuestion(currentkeypoint?.exams[0].questions[0]);
    //set order of answer options randomly
    currentkeypoint?.exams[0].questions.forEach((question) => {
      question.answers.sort(() => Math.random() - 0.5);
    });
    setActions([
      {
        title: "",
        action: "",
        icon: duotone("arrow-left"),
        execute: () => {
          if (currentQuestionindex.current === 10) {
            showKeypointInfo(false);
            showExam(false);
          } else if (currentQuestionindex.current === 0) {
            showKeypointInfo(true);
            currentQuestionindex.current = 10;
          } else {
            prevQuestion();
          }
        },
      },
      {
        title: lang.Text("Next"),
        action: "",
        icon: duotone("arrow-right"),
        execute: () => {
          if (currentQuestionindex.current === 10) {
            currentQuestionindex.current = 0;
            showKeypointInfo(false);
          } else {
            setGotoNextQuestion(true);
          }
        },
      },
    ]);
    showExam(true);
  };

  const nextQuestion = () => {
    let index = currentQuestionindex.current;
    if (currentkeypoint?.exams[0].questions !== undefined) {
      setCurrentQuestionindex(index + 1);
    }
  };

  const prevQuestion = () => {
    let index = currentQuestionindex.current;
    if (currentkeypoint?.exams[0].questions !== undefined) {
      // if (index !== -1)
      // {
      setCurrentQuestionindex(index - 1);
      // }
    }
  };

  const selectAnswer = (answer: any) => {
    if (currentQuestion !== undefined) {
      let answers = currentQuestion.answers;

      answers.forEach((item) => {
        if (item.id === answer.id) item.selected = true;
        else item.selected = false;
      });

      setCurrentQuestion({ ...currentQuestion, answers: answers });
    }
  };

  const [keypointBindDialog, { open: openBindDialog, close: closeBindDialog }] =
    useDisclosure(false);
  const [
    keypointWrongDialog,
    { open: openWrongDialog, close: closeWrongDialog },
  ] = useDisclosure(false);

  const atkeypoint = (data?: string) => {
    setFlashAction(false);

    let keypoint = currentkeypoint;
    if (keypoint === undefined) {
      console.info("keypoint not found");
    } else {
      //get content
      if (keypoint.contentRef !== undefined && keypoint.contentRef.length > 0) {
        getContentItem(keypoint.contentRef, lang.locale(), false).then(
          (result) => {
            setKeypointContent(result);
            setCurrentQRCode(undefined);

            console.info("keypoint ->", result);

            setActions([
              {
                title: "",
                action: "",
                icon: duotone("arrow-left"),
                execute: () => {
                  showKeypointInfo(false);
                  //showStart(false);
                  //resetActions();
                },
              },
              {
                title: lang.Text("Next"),
                action: "",
                icon: duotone("arrow-right"),
                execute: () => {
                  showKeypointInfo(false);
                  if (
                    currentkeypoint?.exams !== undefined &&
                    currentkeypoint?.exams.length > 0
                  ) {
                    startExam();
                  } else {
                    closeExam();
                  }
                },
              },
            ]);
            showKeypointInfo(true);
          }
        );
      }
    }
  };

  const [startlocation, setStartLocation] = useState<string>("start.plan1");

  useEffect(() => {
    if (currentkeypoint !== undefined && current?.layers !== undefined && current?.layers.length > 0
       && currentkeypoint?.layerRefs?.[0] !== undefined
    ) {
      const layer = current?.layers.find(
        (layer) => layer.id === currentkeypoint?.layerRefs?.[0]
      );
      // console.log("currentlayer: ", layer);
      setCurrentLayer(layer);
    }

    if (currentkeypoint !== undefined) {

      showdirections([startlocation, currentkeypoint?.mapRef]);

      setActions([
        {
          title: lang.Text("Scan QR-code for ") + " " + currentkeypoint.title,
          action: "qr-code-scan",
          icon: duotone("qrcode"),
          execute: () => {
            setFlashAction(false);
            setFirstKeypoint(false);
          },
        },
      ]);
      if (firstKeypoint) {
        setFlashAction(true);
      }
    }
  }, [currentkeypoint]);

  useEffect(() => {
    if (currentQuestion !== undefined) {
      let isCorrect = false;
      currentQuestion?.answers.forEach((answer) => {
        if (answer.selected && answer.isCorrect) {
          isCorrect = true;
        }
      });
      if (isCorrect) {
        console.info("Correct answer");
        setWrongAnswer(false);
      } else {
        console.info("Wrong answer");
        setWrongAnswer(true);
      }
    }
  }, [currentQuestion]);


  useEffect(() => {
    if (current?.config.demoMode) {
      //no check
      atkeypoint();
    } else {
      //console.info("currentQRCode ->", currentQRCode);
      if (currentkeypoint !== undefined && currentQRCode !== undefined) {
        let keypoint = currentkeypoint;
        if (
          keypoint?.resourceRef !== null &&
          keypoint?.resourceRef !== undefined &&
          keypoint?.resourceRef.length > 0
        ) {
          //check if correct
          if (
            keypoint?.resourceRef.toLowerCase() === currentQRCode.toLowerCase()
          ) {
            //correct
            atkeypoint();
          } else {
            //wrong
            openWrongDialog();
          }
        } else {
          if (current?.config.allowBindKeypoints) {
            openBindDialog();
          }
        }
      }
    }
  }, [currentQRCode]);

  const bindKeypoint = () => {
    closeBindDialog();

    fetcher<ResponseData<KeyPoint>>(
      GlobalAppsettings.BaseUrl +
        "/api/training/bindKeypoint?id=" +
        id +
        "&orgId=" +
        current?.orgId +
        "&keypointId=" +
        currentkeypoint?.id +
        "&code=" +
        currentQRCode,
      Http.POST
    )
      .then((data: ResponseData<KeyPoint>) => {
        if (data !== undefined) {
          if (data.success === true) {
            
            window.alert(lang.Text('Nyckelpunkt kopplad.'));

            let round = current;
            let keypoint = currentkeypoint;
            let index = keypoint?.order;
            //set next keypoint
            if (keypoint != undefined) {
              index = keypoint.order + 1;
            }

            if (
              index !== undefined &&
              round !== undefined &&
              index < round?.keyPoints.length
            ) {
              let nextKeypoint = round.keyPoints.find(
                (kp) => kp.group === currentGroup && kp.order === index
              );

              setCurrentKeypoint(nextKeypoint);
              // setShowKeypointList(true);
            }
            setCurrentQRCode(undefined);
            showKeypointInfo(false);
            closeExam();
          } else {
            window.alert(data.errorMessage);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getTransform = (keypoint: KeyPoint) => {
    let x = (mapWidth * Number.parseFloat(keypoint.coordinateX)) / 100;
    let y = ((mapHeight - 35) * Number.parseFloat(keypoint.coordinateY)) / 100;

    return "translate(" + x + "px," + y + "px)";
  };

  const renderKeyPointImages = (images: ContentImage[]) => {
    if (images.length > 0) {
      if (images[0].url.endsWith(".mp4")) {
        return (
          <div>
            <video
              controls={false}
              className="w-full"
              style={{ height: "86vh", objectFit: "cover" }}
              src={images[0].url}
              autoPlay
            />
          </div>
        );
      }
    }
  };

  const renderImageMap = () => {
    return (
      <ScrollArea h={height} viewportRef={viewport} scrollbarSize={0}>
        <div className="pb-76 min-h-screen min-w-screen">
          <Paper
            className="overflow-auto touch-auto h-full mt-5 mb-80 justify-center"
            // ref={mapRef}
          >
            <Box
              className="w-full h-full origin-top-left "
              style={{ transform: "scale(" + scale + ")" }}
              ref={mapContainerRef}
            >
              <Image
                src={mapMediaRef}
                radius="lg"
                className="origin-top-left"
                //className={"w-full h-full origin-top-left"}
                //fit='fill'
                //w={'auto'}
                //style={{transform: 'scale(' + scale + ')'}}
              />
              <div
                style={
                  {
                    // pointerEvents: 'none',
                    //transform: 'scale(' + -scale + ')'
                  }
                }
                className="absolute top-4 left-0 right-0 origin-top-left"
              >
                {currentkeypoints?.map((keypoint, key) => {
                  if (
                    currentkeypoint !== undefined &&
                    keypoint.id === currentkeypoint?.id
                  ) {
                    return (
                      <span
                        className={"fixed flex h-6 w-6 "}
                        key={key}
                        style={{
                          transform: getTransform(keypoint),
                          zIndex: 100,
                        }}
                      >
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-600 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-6 w-6 bg-green-700">
                          <span className="text-white text-center w-6 pointer-events-none">
                            {keypoint.order + 1}
                          </span>
                        </span>
                      </span>
                    );
                  } else {
                    return (
                      <span
                        className={"fixed flex h-6 w-6"}
                        key={key}
                        style={{ transform: getTransform(keypoint) }}
                        onClick={() => setCurrentKeypoint(keypoint)}
                      >
                        <span
                          className={
                            keypoint.isDone
                              ? "relative inline-flex rounded-full h-6 w-6 border-2 bg-green-600"
                              : "relative inline-flex rounded-full h-6 w-6 border-2 bg-gray-700"
                          }
                        >
                          <span className="text-white text-center mt-[-2px] w-6 pointer-events-none">
                            {keypoint.order + 1}
                          </span>
                        </span>
                      </span>
                    );
                  }
                })}
              </div>
            </Box>
          </Paper>

          {/* <Group justify="end" className="h-16">
      {showKeypointList ? 
      <ActionIcon color="gray" variant="transparent" onClick={() => setShowKeypointList(false)}><FontAwesomeIcon icon={light('angle-down')} size="xl"/></ActionIcon>
      :
      <ActionIcon color="gray" variant="transparent" onClick={() => setShowKeypointList(true)}><FontAwesomeIcon icon={light('angle-up')} size="xl"/></ActionIcon>
      }
        </Group> */}
        </div>
      </ScrollArea>
    );
  };

  const saveKeyPoint = (keyPoint: KeyPoint, x: string, y: string) => {
      // Update keypoint coordinates
      const updatedKeyPoint = {
        ...keyPoint,
        coordinateX: x,
        coordinateY: y
      };

    if (currentkeypoints !== undefined) {

    fetcher<ResponseData<KeyPoint>>(
      GlobalAppsettings.BaseUrl +
        "/api/training/updateKeypoint?id=" + current?.id + "&orgId=" + current?.orgId,
      Http.POST,
      undefined,
      updatedKeyPoint
    )
      .then((data: ResponseData<KeyPoint>) => {
        if (data !== undefined) {
          if (data.success === true) {
            refresh();
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }
  }

  

  const [user, setUser] = useState<string>('');
  const [userList, setUserList] = useState<string[]>([]);
  const [orgInfo, setOrgInfo] = useState<string>('');

  const handleUpdate = (user: string, userList: string[], orgInfo: string) => {
    
    console.info("userinfo update ->", user, userList, orgInfo);
    
    setUser(user);
    setUserList(userList);
    setOrgInfo(orgInfo);
  }

  const [currentGroup, setCurrentGroup] = useState<string>("");

  const [currentGroupRef, setCurrentGroupRef] = useState<KeypointGroup | undefined>(undefined);

  useEffect(() => {
    if (currentGroup !== undefined) {
      var group = current?.groups.find((group) => group.id === currentGroup);
      if (group !== undefined && group.mapRef !== undefined) {
        var keypoints = current?.keyPoints.filter(
          (d) => d.group === currentGroup || d.group === "global"
        );

        if (keypoints !== undefined && keypoints.length > 0) {
          setStartLocation(group.mapRef);
          if (start === false) {
            setCurrentKeypoint(keypoints[0]);
          }
        }

        setCurrentGroupRef(group);
        showMap(group.title);
        showPointOnMap(group.mapRef);
      }
    }
  }, [currentGroup, start]);


  const renderLayer = () => {
    if (currentLayer !== undefined) {
      switch (currentLayer.type) {
        case "keypoint.layer":
          return renderImageMap();
        case "map.layer.3d":
          return <div style={{width: '100vw', height: '100vh'}}>
          {renderMap()}
          </div>;
        case "map.layer.2d":
          return <div style={{width: '100vw', height: '100vh'}}>
          <MapModule layer={currentLayer} keyPoints={currentkeypoints} 
            mapLoaded={onMapLoaded}
            currentGroup={currentGroupRef}
            saveKeyPoint={saveKeyPoint} editMode={props.editMode} 
            currentKeyPoint={currentkeypoint}
            />
          </div>;
      }
    }
  };

  return (
    <>
      {/* <Grid 
        grow
        // cols={{ base: 1, sm: 2, lg: 2, xl: 2 }} 
        //spacing={0}
        >
        <Grid.Col span={{ base: 4, md: 8, lg: 10 }}> */}
      <Portal>
        <Stack className="fixed top-16" gap={6}>
          {current?.logoUrl && (
            <div
              className="h-18 w-20 z-20 mt-2 p-2 border border-gray-300 rounded-r-md bg-white"
              onClick={() => showStart(true)}
            >
              <Image src={current?.logoUrl} fit="cover" />
            </div>
          )}
          {/* <div className='fixed top-16 left-24 z-50'>
              <p className='font-light text-md text-gray-400'>{current?.title}</p>
            </div> */}
          {current?.groups !== undefined &&
            current?.groups.length > 0 &&
            current?.groups.map((group, key) => {
              return (
                <div
                  key={key}
                  className={
                    "h-10 z-20 pt-2 border border-gray-300 rounded-r-md transition-all duration-300 cursor-pointer " +
                    // + (key === 0 ? ' top-48' : key===1 ? 'top-60' : 'top-72') + ' '
                    (currentGroup === group.id
                      ? "bg-gray-900 text-white font-bold border-l-4 border-lime-500 px-5"
                      : "bg-white text-gray-500 font-light px-4")
                  }
                  onClick={() => setCurrentGroup(group.id)}
                >
                  <p className="text-md pointer-events-none">{group.title}</p>
                </div>
              );
            })}
        </Stack>
      </Portal>

      {renderLayer()}

      {/* </Grid.Col> */}
      {/* <Grid.Col span={{ base: 4, md: 4, lg: 2 }}> */}
      {/* Keypoint menu */}
      <Portal>
        <Box
          hiddenFrom="sm"
          className=""
          style={{
            zIndex: 20,
            position: "fixed",
            top: "75px",
            //bottom: '95px',
            right: "10px",
          }}
        >
          <Button
            variant="outline"
            size="lg"
            radius="xl"
            color={"#1f2937"}
            className=" bg-gray-100"
            onClick={() => setShowKeypointList(true)}
          >
            <FontAwesomeIcon icon={light("list")} size="lg" />
          </Button>
          {/* <Button onClick={reportFinishedRound}>Test</Button> */}
        </Box>
      </Portal>

      {/* Keypoint list */}
      <Drawer
        opened={isMobile ? showKeypointList : true}
        onClose={() => setShowKeypointList(false)}
        position={isMobile ? "bottom" : "right"}
        padding={0}
        withOverlay={false}
        overlayProps={{ backgroundOpacity: 0, blur: 0 }}
        withCloseButton={isMobile ? true : false}
        closeButtonProps={{ size: "xl", mr: 2, mt: 2, color: "dark-gray" }}
        size={"45%"}
        transitionProps={{
          duration: 800,
          transition: isMobile ? "slide-up" : "slide-left",
        }}
        styles={{
          content: {
            backgroundColor: "white",
            color: Exagard_Colors._background,
            padding: 0,
            borderRadius: isMobile ? "1rem 1rem 0 0" : "none",
            border: isMobile
              ? "1px solid " + Exagard_Colors._medium_gray
              : "none",
            marginTop: isMobile ? 0 : "65px",
          },
          inner: isMobile
            ? { fontSize: 20, width: "100%" }
            : { fontSize: 20, display: "block", width: "30%", right: 0 },
        }}
        style={{
          zIndex: 15,
          position: "fixed",
        }}
        title={
          <div className="px-4 py-2 text-gray-500 font-thin text-sm ">
            {current?.title} -{" "}
            {current?.groups.find((group) => group.id === currentGroup)?.title}
          </div>
        }
      >
        <ScrollArea
          // h={250}
          className="mt-2"
          variant="hover"
        >
          <Stack gap={8} className="pt-2 mb-[250px] p-4">
            {current?.keyPoints
              .filter((d) => d.group === currentGroup || d.group === "global")
              .map((item, key) => {
                if (item.id === currentkeypoint?.id)
                  return (
                    <Button
                      variant="filled"
                      radius={"md"}
                      color={Exagard_Colors._dark_green}
                      key={key}
                      onClick={() => {
                        // setShowKeypointList(false);
                        setCurrentKeypoint(item);
                      }}
                      justify="space-between"
                      rightSection={<></>}
                      // className='p-6 text-white'
                      // p={'xl'}
                      size="lg"
                    >
                      {" "}
                      {}
                      {item.order + 1}. {item.title}
                    </Button>
                  );
                else
                  return (
                    <Button
                      variant="outline"
                      radius={"md"}
                      color={"#e5e7eb"}
                      size="lg"
                      key={key}
                      className={
                        item.isDone
                          ? "bg-lime-300 text-gray-700"
                          : "bg-white text-gray-700"
                      }
                      onClick={() => {
                        // setShowKeypointList(false);
                        setCurrentKeypoint(item);
                      }}
                      justify="space-between"
                      rightSection={
                        item.isDone ? (
                          <FontAwesomeIcon
                            icon={solid("check-circle")}
                            size="xl"
                            color={Exagard_Colors._dark_green}
                          />
                        ) : (
                          <></>
                        )
                      }
                    >
                      <div className="text-gray-800">
                        {item.order + 1}. {item.title}
                      </div>
                    </Button>
                  );
              })}
          </Stack>
        </ScrollArea>
      </Drawer>

      {/* Exam content*/}
      <Drawer
        opened={exam}
        onClose={() => showExam(false)}
        position="bottom"
        padding={0}
        overlayProps={
          isMobile
            ? { backgroundOpacity: 0.5, blur: 4 }
            : { backgroundOpacity: 0.5, blur: 4 }
        }
        withCloseButton={false}
        size={"95%"}
        transitionProps={{ duration: 1200, transition: "slide-up" }}
        styles={{
          content: {
            backgroundColor: "white",
            color: Exagard_Colors._background,
            padding: 0,
            borderRadius: isMobile ? "1rem 1rem 0 0" : "1rem",
            border: isMobile
              ? "1px solid " + Exagard_Colors._light_gray
              : "1px solid " + Exagard_Colors._light_gray,
            position: "fixed",
            top: isMobile ? "65px" : "65px",
            left: isMobile ? "0px" : "10vw",
            right: isMobile ? "0px" : "10vw",
            bottom: isMobile ? "0px" : "65px",
          },
          inner: isMobile
            ? { fontSize: 20 }
            : { display: "block", width: "50%", right: 0 },
        }}
        // style={{ zIndex: 200, position: 'fixed' }}
      >
        <Card>
          <Card.Section h={isMobile ? 120 : 150} className="bg-blacK">
            {keypointContent?.media && (
              <Image
                src={keypointContent?.media.url}
                alt={"round-logo"}
                className="min-h-[120px] max-h-[120px]"
                fit="contain"
              />
            )}
            {/* {current?.logoUrl &&
                <div className="absolute top-0">
                    <Image
                        src={current?.logoUrl}
                        fit="cover"
                    />
                </div>} */}
          </Card.Section>

          {keypointInfo ? (
            <>
              <Card.Section className="overflow-auto min-h-[250px] py-2 px-4 pb-20">
                <Title order={4} className="py-4">
                  {keypointContent?.caption}
                </Title>
                {renderMarkdown(keypointContent?.copy ?? "")}
              </Card.Section>
            </>
          ) : (
            <>
              <Card.Section className="min-h-[50px] py-2 px-4">
                <Text className="py-4 font-semibold">
                  {currentQuestion?.title}
                </Text>
              </Card.Section>
              <Card.Section className="min-h-[50px] py-2 px-4">
                <Text className="text-xl font-light">
                  {currentQuestion?.text}
                </Text>
              </Card.Section>
              <Card.Section className="min-h-[50px] p-8 pb-56">
                <Stack>
                  {currentQuestion?.answers.map((answer, key) => {
                    return (
                      <Paper
                        radius={"md"}
                        withBorder
                        className="font-light p-4"
                        onClick={() => {
                          selectAnswer(answer);
                        }}
                        //variant={answer.selected ? 'filled' : 'outline'}
                        key={key}
                        c={answer.selected ? "white" : Exagard_Colors._gray}
                        bg={
                          answer.selected ? Exagard_Colors._green : "whitesmoke"
                        }
                      >
                        {answer.text}
                      </Paper>
                    );
                  })}
                </Stack>
                {/* {wrongAnswer ?<>
                  <Alert color='red'>{lang.Text("Wrong answer")}</Alert>
                  </> : <></>} */}
              </Card.Section>
            </>
          )}
        </Card>
      </Drawer>

      {/* Wrong answer dialog */}
      <Modal
        opened={wrongAnswerDialog}
        onClose={() => {
          // setWrongAnswer(false);
          closeWrongAnswerDialog();
        }}
      >
        <Title order={4}>{lang.Text("Wrong answer")}</Title>
        <Text className="my-4">
          {lang.Text("Look thru the options and try again.")}
        </Text>
      </Modal>

      {/* Achivement content*/}
      <Drawer
        opened={achivements}
        onClose={() => showAchivements(false)}
        position="bottom"
        padding={0}
        overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
        withCloseButton={false}
        size={"95%"}
        transitionProps={{ duration: 1200, transition: "slide-up" }}
        styles={{
          content: {
            backgroundColor: "white",
            color: Exagard_Colors._background,
            padding: 0,
            borderRadius: "1rem 1rem 0 0",
            border: "1px solid " + Exagard_Colors._gray,
          },
          // inner: { fontSize: 20 },
        }}
        style={{ zIndex: 200, position: "fixed" }}
      >
        <Card>
          <Card.Section h={160} className="bg-blacK">
            {achivementContent?.contentItem?.media && (
              <Image
                src={achivementContent?.contentItem?.media.url}
                alt={"round-logo"}
                className="min-h-[160px] max-h-[160px]"
                fit="cover"
              />
            )}
            {/* {current?.logoUrl &&
                <div className="absolute top-0">
                    <Image
                        src={current?.logoUrl}
                        fit="cover"
                    />
                </div>} */}
          </Card.Section>
          <Card.Section className="min-h-[250px] py-2 px-4">
            <Title order={4} className="py-4">
              {achivementContent?.contentItem?.caption}
            </Title>
            {renderMarkdown(achivementContent?.contentItem?.copy ?? "")}
          </Card.Section>
          <Card.Section>
            <Lottie animationData={stars} />
          </Card.Section>
        </Card>
      </Drawer>

      {/* Keypoint content*/}
      <Drawer
        opened={keypointInfo}
        onClose={() => showKeypointInfo(false)}
        position="bottom"
        padding={0}
        overlayProps={
          isMobile
            ? { backgroundOpacity: 0.5, blur: 4 }
            : { backgroundOpacity: 0.5, blur: 4 }
        }
        withCloseButton={false}
        size={"95%"}
        transitionProps={{ duration: 1200, transition: "slide-up" }}
        styles={{
          content: {
            backgroundColor: "white",
            color: Exagard_Colors._background,
            padding: 0,
            borderRadius: isMobile ? "1rem 1rem 0 0" : "1rem",
            border: isMobile
              ? "1px solid " + Exagard_Colors._light_gray
              : "1px solid " + Exagard_Colors._light_gray,
            // marginTop: isMobile ? 0 : '65px',
            position: "fixed",
            top: isMobile ? "65px" : "65px",
            left: isMobile ? "0px" : "10vw",
            right: isMobile ? "0px" : "10vw",
            bottom: isMobile ? "0px" : "65px",
          },
          inner: isMobile
            ? { fontSize: 20 }
            : { display: "block", width: "50%", right: 0 },
        }}
        style={{ zIndex: 200, position: "fixed" }}
      >
        <Modal
          opened={zoomKeypoint}
          onClose={() => showZoomKeypoint(false)}
          title={currentkeypoint?.title}
          centered
        >
          {keypointContent?.media && (
            <Image
              src={keypointContent?.media.url}
              alt={"round-logo"}
              className="w-full h-full"
              fit="cover"
            />
          )}
        </Modal>
        <Card>
          <Card.Section h={160} className="bg-blacK">
            {keypointContent?.media && (
              <>
                <Image
                  src={keypointContent?.media.url}
                  alt={"round-logo"}
                  className="min-h-[160px] max-h-[160px]"
                  fit="contain"
                  onClick={() => showZoomKeypoint(true)}
                />
                <div className="absolute top-0 right-0">
                  <Button
                    color="white"
                    variant="subtle"
                    onClick={() => showZoomKeypoint(true)}
                  >
                    {lang.Text("Press here to zoom")}
                  </Button>
                </div>
              </>
            )}
            {/* {current?.logoUrl && (
                  <div className='absolute top-0'>
                    <Image src={current?.logoUrl} fit='cover' />
                  </div>
                )} */}
          </Card.Section>
          <ScrollArea>
            <Card.Section className="min-h-[250px] py-2 px-4 mb-20">
              {keypointContent?.images && keypointContent.images.length > 0 ? (
                <>{renderKeyPointImages(keypointContent?.images)}</>
              ) : (
                <>
                  <Title order={4} className="py-4">
                    {keypointContent?.caption}
                  </Title>
                  {renderMarkdown(keypointContent?.copy ?? "")}
                </>
              )}
            </Card.Section>
          </ScrollArea>
        </Card>
      </Drawer>

      {/* Start content*/}
      <Drawer
        opened={start}
        onClose={() => showStart(false)}
        position={isMobile ? "bottom" : "top"}
        padding={0}
        overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
        withCloseButton={false}
        size={isMobile ? "95%" : "65%"}
        transitionProps={{ duration: 1200, transition: "slide-up" }}
        styles={{
          root: { zIndex: 200 },
          content: {
            backgroundColor: "white",
            color: Exagard_Colors._background,
            padding: 0,
            borderRadius: isMobile ? "1rem 1rem 0 0" : "1rem",
            border: "1px solid " + Exagard_Colors._gray,
            position: "fixed",
            top: isMobile ? "65px" : "65px",
            left: isMobile ? "0px" : "20vw",
            right: isMobile ? "0px" : "20vw",
          },
          // inner: { fontSize: 20 },
        }}
        style={{ zIndex: 200, position: "fixed" }}
      >
        <Card>
          <Card.Section h={160} className="bg-blacK">
            {current?.imageUrl && (
              <Image
                src={current?.imageUrl}
                alt={"round-logo"}
                className="min-h-[160px] max-h-[160px] "
                fit="cover"
              />
            )}
            {current?.logoUrl && (
              <div className="absolute top-0 p-1 border border-gray-300 rounded-r-xl bg-white">
                <Image src={current?.logoUrl} fit="cover" />
              </div>
            )}
          </Card.Section>
          <Card.Section className="min-h-[50px] py-2 px-4">
            <Title order={4}>{current?.title}</Title>
            <Title order={3} className="py-4">
              {startContent?.caption}
            </Title>
            {renderMarkdown(startContent?.copy ?? "")}
          </Card.Section>
          <UserFormInput userOrgConfig={userOrgConfig} onUpdate={handleUpdate} ></UserFormInput>
        </Card>
      </Drawer>

      <Modal
        opened={keypointBindDialog}
        onClose={() => {
          closeBindDialog();
          setCurrentQRCode(undefined);
        }}
        title={lang.Text("Bind Keypoint?")}
        centered
      >
        <Text className="p-6">
          {lang.Text("Do you want to bind the Keypoint to this QR code?")}
        </Text>
        <Divider className="my-8" />
        <Group grow>
          <Button variant="filled" onClick={() => bindKeypoint()}>
            {lang.Text("Yes")}
          </Button>
          <Button
            variant="outline"
            onClick={() => {
              closeBindDialog();
              setCurrentQRCode(undefined);
              showKeypointInfo(false);
            }}
          >
            {lang.Text("No")}
          </Button>
        </Group>
      </Modal>

      {/*Wrong keypoint dialog*/}
      <Modal
        opened={keypointWrongDialog}
        onClose={() => {
          showKeypointInfo(false);
          closeWrongDialog();
        }}
        title={lang.Text("Wrong keypoint")}
        centered
      >
        <Text className="p-6">
          {lang.Text("Find the correct keypoint and scan that QR-code")}
        </Text>
      </Modal>

      <QRScanner
        demoMode={current?.config.demoMode}
        onScanned={(data: string) => {
          setCurrentQRCode(data);
          showKeypointInfo(true);
          console.log(keypointInfo, "<--------");
        }}
        dialog={isMobile ? false : true}
      />


      <AppMenu actions={actions} flash={false}  ></AppMenu>
    </>
  );
};

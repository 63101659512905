import { Canvas, ThreeEvent, useThree, Vector3 } from "@react-three/fiber";
import { Box, CameraControls, OrbitControls, OrthographicCamera, useTexture  } from "@react-three/drei";
import { Plane } from "@react-three/drei";
import { Texture } from "@react-three/drei";
import { Image } from '@react-three/drei'
import { KeyPoint, KeypointGroup, Layer } from "../api/TrainingApi";
import { Text } from '@react-three/drei'
import { Exagard_Colors, GlobalAppsettings } from "../../../GlobalSettings";
import { Suspense, useEffect, useRef, useState } from "react";
import { useDrag, useGesture } from "@use-gesture/react";
import { animated, useSpring } from "@react-spring/three";

import * as THREE from "three";
import { Fire } from "./effects/FireEffect";
import { useMediaQuery, useOs } from "@mantine/hooks";


const BackgroundMap = (props: {floorPlane: THREE.Plane, url: string}) => {
    return ( 
        <Image 
            scale={1}
            rotation={[-Math.PI / 2, 0, 0]}
            position={[0, -0.1, 0]}
            toneMapped={false}  
            url={props.url} >
            <planeGeometry args={[30, 30]} />
        </Image>
    )
}


const evacuation_route = "https://images.ctfassets.net/eeuyi8y7y8ak/4sTBPYpH3xCbFsk9hXCiSX/b9cf68a9f49b73430cde34caaa3c1d8b/evacuation-route.png";
const defilibrator = "https://images.ctfassets.net/eeuyi8y7y8ak/7gROafVRj9j8AhAh1jXpPC/4637f63a5086903454fa03325b24e4ea/defilibrator.png";
const evacuation_plan = "https://images.ctfassets.net/eeuyi8y7y8ak/7AhCPwmjhg95CIvOf8eCKe/4e26c882985e3e8ed46830952eb395e0/evacuation-plan.png";
const evacuation_point = "https://images.ctfassets.net/eeuyi8y7y8ak/5snmkJXgRMC5jh0SKyME4L/1044ffc7c52488c448ee94fa196d7f35/evacuation-point.png";
const fire_ext = "https://images.ctfassets.net/eeuyi8y7y8ak/c4debe73-cc72-431a-9a9c-7215cdc48d97/3d369c772694f83451b1da74ef163ef7/c4debe73-cc72-431a-9a9c-7215cdc48d97";
const evacuation_bag = "https://images.ctfassets.net/eeuyi8y7y8ak/5VQCJBGQjj0KORF6OfamhJ/1a786a7069b0db8507688e459af7bdc9/evacuation-bag.png";
const medic = "https://images.ctfassets.net/eeuyi8y7y8ak/28oAOAPHj2XoqAUwjDWnfx/6aa4ec438af71007d9e50ad60cd96f07/medic.png";

const getKeyPointTexture = (type: string) => {
    if (type.includes("evacuation.route")) return evacuation_route;
    if (type.includes("defilibrator")) return defilibrator;
    if (type.includes("evacuation.plan")) return evacuation_plan;
    if (type.includes("evacuation.point")) return evacuation_point;
    if (type.includes("evacuation.bag")) return evacuation_bag;
    if (type.includes("medic")) return medic;
    if (type.includes("fire.ex")) return fire_ext;
    return defilibrator;
}

interface RayCast extends THREE.Raycaster {
    intersectPlane: (plane: THREE.Plane, point: THREE.Vector3) => void;
}

interface RayEvent extends THREE.Event {
    ray: RayCast;
}



const KeyPointMarker = (props: {keyPoint: KeyPoint, 
        setIsDragging: (value: boolean) => void,
        floorPlane: THREE.Plane,
        saveKeyPoint?: (keyPoint: KeyPoint, position: THREE.Vector3) => void,
        editMode?: boolean
    }) => {
    
    const [pos, setPos] = useState([props.keyPoint.coordinateX, 0.1, props.keyPoint.coordinateY]);
    const [scale, setScale] = useState(1.5);

    const dragObjectRef = useRef();
    let planeIntersectPoint = new THREE.Vector3();

    const bind = useGesture({
        onDrag: ({ movement: [x, y], active, event }) => {
             if (active) {                
                let _event = event as unknown as RayEvent;

                

                _event.ray.intersectPlane(props.floorPlane, planeIntersectPoint);
                setPos([planeIntersectPoint.x, 0.1, planeIntersectPoint.z]);
            }
        }
    })

    const handlePointerDown = (event: ThreeEvent<PointerEvent>) => {
        if (props.editMode) {
            props.setIsDragging(true);
            let c = bind();
            if (c && c.onPointerDown) c.onPointerDown(event as unknown as React.PointerEvent)
        }
      }

    const handlePointerUp = (event: ThreeEvent<PointerEvent>) => {
        if (props.editMode) {
            props.setIsDragging(false);
        
            if (props.saveKeyPoint) {
                props.saveKeyPoint(props.keyPoint, new THREE.Vector3(Number(pos[0]), 0.1, Number(pos[2])));
            }
            let c = bind();
            if (c && c.onPointerUp) c.onPointerUp(event as unknown as React.PointerEvent);
        }
    }

    const handlePointerMove = (event: ThreeEvent<PointerEvent>) => {
        if (props.editMode) {
            let c = bind();
            if (c && c.onPointerMove) c.onPointerMove(event as unknown as React.PointerEvent)
        }
    }

    

    const texture = useTexture({
        map: getKeyPointTexture(props.keyPoint.mapRef)
        
    });

    return (<>
        <Box
            args={[1, 1, 0.05]}
            scale={scale}
            rotation={[-Math.PI / 2, 0, 0]}
            onPointerDown={handlePointerDown}
            onPointerMove={handlePointerMove}
            onPointerUp={handlePointerUp}
            onPointerOver={() => props.editMode ? setScale(1.8) : null}
            onPointerOut={() => props.editMode ? setScale(1.5) : null}
            position={pos as Vector3}
            ref={dragObjectRef.current}
        >
            <meshBasicMaterial {...texture} />
        </Box>
        {/* <Image 
            scale={1}
            onPointerMove={() => console.log("move")}
            // color={"white"}
            rotation={[-Math.PI / 2, 0, 0]}
            position={[0, 0.8, 5]}
            toneMapped={false}
              
            url={"https://images.ctfassets.net/eeuyi8y7y8ak/01zoEPoWDEs8iTc2e3cKY3/f87423d3219789346f3cef446c88b0db/_tersam.gif"}
            //</>url={"https://images.ctfassets.net/eeuyi8y7y8ak/c4debe73-cc72-431a-9a9c-7215cdc48d97/3d369c772694f83451b1da74ef163ef7/c4debe73-cc72-431a-9a9c-7215cdc48d97?h=250"} 
            >
            <planeGeometry args={[1, 1]} />
        </Image>  */}
    </>)
}


function CanvasReady(props: {onReady: () => void}) {
    useEffect(() => {
      props.onReady();
    }, [])
    return null
  }

export const MapModule = (props: {layer: Layer, keyPoints?: KeyPoint[],
        mapLoaded?: () => void,
        saveKeyPoint?: (keyPoint: KeyPoint, x: string, y: string) => void,
        editMode?: boolean,
        currentKeyPoint?: KeyPoint,
        currentGroup?: KeypointGroup 
    }) => {

    const cameraControlsRef = useRef<CameraControls>(null);
    //const isMobile = useMediaQuery('(max-width: 768px)');
    const os = useOs();
    const isMobile = os === 'ios' || os === 'android';

    const [isDragging, setIsDragging] = useState(false);
    const floorPlane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);

    const [cameraPosition, setCameraPosition] = useState<Vector3>([0, 40, 0]);
    const [currentZoom, setCurrentZoom] = useState(40);

    const saveKeyPoint = (keyPoint: KeyPoint, position: THREE.Vector3) => {
        if (props.saveKeyPoint) {
            
            console.log("position: " + position);
                        
            props.saveKeyPoint(keyPoint, position.x.toString(), position.z.toString());
        }
    }

    useEffect(() => {
        if (props.currentKeyPoint) {      
            if (cameraControlsRef.current) {
                (cameraControlsRef.current as any).setLookAt(
                    Number(props.currentKeyPoint.coordinateX),currentZoom, Number(props.currentKeyPoint.coordinateY),
                    Number(props.currentKeyPoint.coordinateX),0, Number(props.currentKeyPoint.coordinateY),
                    true
                );
            }
        }
    }, [props.currentKeyPoint])

    // useEffect(() => {
    //     if (props.currentGroup) {
    //         console.log("currentGroup: ", props.currentGroup);
    //         if (cameraControlsRef.current && props.currentGroup.posX && props.currentGroup.posY) {
    //             (cameraControlsRef.current as any).setLookAt(
    //                 props.currentGroup.posX, props.currentGroup.posY, 0,
    //                 props.currentGroup.posX, 0, props.currentGroup.posY,
    //                 true
    //             );
    //         }
    //     }
    // }, [props.currentGroup])

    useEffect(() => {
        //console.log("layer: ", props.layer);
        // if (props.layer.zoom) {
        //     if (cameraControlsRef.current) {
        //         (cameraControlsRef.current as any).setZoom(Number(props.layer.zoom));
        //     }
        // }
        
    }, [props.layer])


    const renderMapLayer = (layer: Layer) => {
        if (layer.type === "map.layer.2d") 
            return <BackgroundMap floorPlane={floorPlane} url={layer.mediaRef} />
        //if (layer.type === "map.layer.3d")

        return null;
    }
    
    return (
    <Canvas style={{ background: "white" }} 
        dpr={[1, 2]}
        
    >
    <Suspense fallback={null}>
        
      <CanvasReady onReady={props.mapLoaded ? () => {
        if (props.mapLoaded) props.mapLoaded();
        console.log("map loaded ->", props.layer);
      } : () => { console.log("map loaded")}} />


      <ambientLight intensity={1.5} />
      
      {renderMapLayer(props.layer)}
   
      {/* <planeHelper args={[floorPlane, 4 , 0x65a30d]} /> */}

     {props.editMode ? <gridHelper args={[100, 100, "#DFDFDF", "#DFDFDF"]} /> : null}

      {props.keyPoints?.map((keyPoint) => (
          <KeyPointMarker key={keyPoint.id} keyPoint={keyPoint} 
          setIsDragging={setIsDragging} 
          floorPlane={floorPlane} 
          saveKeyPoint={saveKeyPoint} 
          editMode={props.editMode}
          />
      ))}

     <OrthographicCamera makeDefault 
        zoom={currentZoom} 
        position={cameraPosition}
      />
      

    {/* <Text color={Exagard_Colors._dark_green} anchorX="center" anchorY="middle"
            position={[-20, 0.1, 0]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={1.1}
            fontWeight={1000}
        >
            Utrymningsväg
    </Text> */}

    {/* <Fire scale={2} color={0xeeeeee} position={[0, -5, 0]} /> */}

    </Suspense>

    <CameraControls
        ref={cameraControlsRef}
        makeDefault
        enabled={!isDragging}
        minPolarAngle={- Math.PI / 90}
        maxPolarAngle={ Math.PI /4}
        minZoom={10} 
        maxZoom={50}
        smoothTime={0.8}

        touches={{
            one: 64,
            two: 512,
            three: 32,
          }}
          mouseButtons={{
            left: 2,
            middle: 16,
            right: 1,
            wheel: 16
          }}
          dollySpeed={isMobile ? 2.6 : 1}
    />

  
    </Canvas>
    )
}
import { duotone, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AppShell,
  Group,
  ActionIcon,
  Transition,
  Button,
  Portal,
  Box,
  em,
} from '@mantine/core';
import { Exagard_Colors, GlobalAppsettings } from '../../../GlobalSettings';
import { useLanguage } from '../../../common/language/Language';
import { useNavigate } from 'react-router-dom';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { usePubsub } from '../../../common/pubsub/usePubsub';
import { joinClassNames } from '../../../common/utilities';
import { useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';

export interface ActionItem {
  title: string;
  action: string;
  icon: IconDefinition;
  data?: any;
  execute?: (data?: any) => void;
  flash?: boolean;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export const AppMenu = (props?: { actions: ActionItem[]; flash?: boolean }) => {
  const lang = useLanguage();
  let navigate = useNavigate();
  const app = usePubsub();

  const [useBackdrop, setUseBackdrop] = useState(false);

  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

  const doAction = (item: ActionItem, data?: any) => {
    if (item?.execute !== undefined) {
      item.execute(data);
    }

    if (item.action.startsWith('/')) {
      navigate(item.action);
    } else if (item.action.startsWith('https')) {
      window.open(item.action, '_self');
    } else if (item.action === 'back') {
      navigate(-2);
    } else {
      app.publish(item.action, data);
    }
  };

  return (
    <>
      <Portal>
        <Box
          className={classNames(
            isMobile ? 'fixed bottom-0 right-0 left-0 rounded-t-xl' : 'fixed bottom-[3vh] right-20 left-20 rounded-xl',
            'text-white p-4 border-0',
            props?.flash ? 'h-28 animate-bounce' : 'h-28',
            useBackdrop
              ? 'backdrop-blur-md backdrop-brightness-150 bg-gray-900'
              : 'bg-gray-800'
          )}
          style={{
            zIndex: 1500,
            position: 'fixed',
          }}
        >
          <Group  align={ isMobile ? 'flex-start' : 'center'} justify={ isMobile ? 'space-between': 'space-around'}>
            {/* {props?.allowEdit ? <Button variant='transparent' onClick={() => props?.setEditMode && props?.setEditMode(!props?.editMode)}>
              <FontAwesomeIcon icon={props?.editMode ? duotone('lock-open') : solid('lock')} size='2xl' color={'white'} />
            </Button> : null} */}
            
            {props?.actions.map((item, key) => {
              if (item.title.length === 0)
                return (
                  <ActionIcon
                    key={key}
                    variant='transparent'
                    p={0}
                    className='mt-2'
                    onClick={() => doAction(item, item.data)}
                    color='white'
                  >
                    <FontAwesomeIcon icon={item.icon} size='2xl' color={'white'} />
                  </ActionIcon>
                );
              else
                return (
                  <Button
                    variant='transparent'
                    color='white'
                    p={0}
                    key={key}
                    size='md'
                    rightSection={
                      <FontAwesomeIcon icon={item.icon} size='2xl' color={'white'} />
                    }
                    onClick={() => doAction(item, item.data)}
                  >
                    {item.title}
                  </Button>
                );
            })}
          </Group>
        </Box>
      </Portal>
    </>
  );
};
